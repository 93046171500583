import React from "react";
import { Group, Stack } from "@mantine/core";
import ToolBar from "@components/toolbar";
import Content from "@components/content/Content";
import "./home.scss";
// import SaveYesorNo from "@components/SavedComponents/YesorNo/YesorNo";

const Home = () => {
  return (
    <Stack>
      <Group justify="center" className="homeContainer">
        <Content />
        {/* <ToolBar /> */}
      </Group>
      {/* <SaveYesorNo /> */}
    </Stack>
  );
};

export default Home;
