import {
  Button,
  Checkbox,
  Flex,
  Group,
  Input,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowsMove,
  IconCopy,
  IconPencil,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import React, { useReducer, useState } from "react";
import ShowMore from "./showMore";
import "./ranking.scss";
import Colors from "../../utils/COLORS";
import dayjs from "dayjs";

const Ranking: React.FC<any> = ({ data }) => {
  const [enable, setEnable] = useState(false);

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "isRequired":
      case "hideNumber":
      case "displayOrder":
        return {
          ...state,
          options: {
            ...state.options,
            [action.type]: !state.options[action.type],
          },
        };

      case "fields":
        return {
          ...state,
          fields: state.fields?.map((item: any) =>
            item.id === action.payload.id
              ? { ...item, field: action.payload.field }
              : item
          ),
        };

      case "deleteField":
        return {
          ...state,
          fields: state.fields?.filter(
            (item: any) => item.id !== action.payload
          ),
        };

      case "addfields":
        return {
          ...state,
          fields: [
            ...state.fields,
            {
              id: dayjs().unix(),
              field: "field",
            },
          ],
        };

      case "identifier":
        return {
          ...state,
          options: { ...state.options, [action.type]: action.payload },
        };

      case action.type:
        return { ...state, [action.type]: action.payload };
      default:
        return state;
    }
  };

  const [objectData, updateData] = useReducer(reducer, data);

  const handleShowMore = () => {
    setEnable(true);
  };

  return (
    <Flex>
      <Stack bg={Colors?.primary} w={40} align="center">
        <IconPencil />
      </Stack>
      <Stack flex={2} p={10}>
        <Group justify="space-between">
          <Text size="xl">Ranking</Text>
          <Group>
            <Group gap={0} justify="flex-end">
              <Button
                variant="subtle"
                color={Colors?.primary}
                radius={0}
                className="cancel-save"
              >
                Cancel
              </Button>
              <Button
                variant="filled"
                color={Colors?.green}
                radius={0}
                className="cancel-save"
              >
                Save
              </Button>
            </Group>
            <Group>
              <Tooltip withArrow label="Copy">
                <IconCopy className="copy-icon" />
              </Tooltip>
              <Tooltip withArrow label="Delete">
                <IconTrash className="delete-icon" />
              </Tooltip>
            </Group>
          </Group>
        </Group>
        <Text c={Colors?.textColor}>Question</Text>
        <Input
          flex={2}
          value={objectData?.question}
          onChange={(e) => {
            updateData({ type: "question", payload: e.target.value });
          }}
        />
        <Text size="xl">Options</Text>
        <Group>
          <Text w={180} c={Colors?.textColor}>
            Required
          </Text>
          <Checkbox
            label="Respondents must answer this question."
            value={objectData?.options?.isRequired}
            onChange={(e) => updateData({ type: "isRequired" })}
          />
        </Group>
        <Group>
          <Text w={180} c={Colors?.textColor}>
            Hide Number
          </Text>
          <Checkbox
            label="Hide the question number."
            value={objectData?.options?.hideNumber}
            onChange={(e) => updateData({ type: "hideNumber" })}
          />
        </Group>
        {!enable && (
          <a href="#" onClick={handleShowMore}>
            Show All Options
          </a>
        )}
        {enable && <ShowMore data={objectData} update={updateData} />}
        <Text size="xl">Fields</Text>
        <Stack gap={5}>
          <Text ml={40} c={Colors?.textColor}>
            Label
          </Text>
          {objectData?.fields?.map(
            ({ id, field }: { id: number; field: string }) => (
              <Group>
                <IconArrowsMove color="#7d848c" width={20} />
                <Input
                  value={field}
                  flex={2}
                  onChange={(e) =>
                    updateData({
                      type: "fields",
                      payload: { id: id, field: e.target.value },
                    })
                  }
                />
                <IconTrash
                  color="#7d848c"
                  onClick={() =>
                    updateData({ type: "deleteField", payload: id })
                  }
                />
              </Group>
            )
          )}
        </Stack>
        <Button
          leftSection={<IconPlus />}
          w={150}
          color="black"
          variant="outline"
          onClick={(e) => updateData({ type: "addfields" })}
          ml={40}
        >
          Add Field
        </Button>
        <Group gap={0} justify="flex-end">
          <Button
            variant="subtle"
            color={Colors?.primary}
            radius={0}
            className="cancel-save"
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color={Colors?.green}
            radius={0}
            className="cancel-save"
          >
            Save
          </Button>
        </Group>
      </Stack>
    </Flex>
  );
};

export default Ranking;
