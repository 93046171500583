import { Checkbox, FileInput, Group, Input, Stack, Text } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import React from "react";
import Colors from "../../utils/COLORS";

const ShowMore: React.FC<any> = ({ data, update }) => {
  return (
    <Stack>
      <Group>
        <Text w={180} c={Colors?.textColor}>
          Display Order
        </Text>
        <Checkbox
          label="Randomize Fields"
          onChange={() => update({ type: "displayOrder" })}
        />
      </Group>
      <Group>
        <Text w={180} c={Colors?.textColor}>
          Identifier
        </Text>
        <Input
          placeholder="Optional-- Quetion text is used if not specified."
          value={data?.options?.identifier}
          onChange={(e) =>
            update({ type: "identifier", payload: e.target.value })
          }
        />
      </Group>
      <Group>
        <Text w={180} c={Colors?.textColor}>
          Media
        </Text>

        <FileInput
          leftSection={<IconPhoto />}
          placeholder="Attach an image"
          color="black"
        />
      </Group>
    </Stack>
  );
};

export default ShowMore;
