// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

* {
  box-sizing: border-box;
}

#app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app.scss"],"names":[],"mappings":"AAAA;;EAEI,WAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,yBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;AACJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
