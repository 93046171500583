export const labelColumnWidth = [
  "10%",
  "20%",
  "30%",
  "40%",
  "50%",
  "60%",
  "70%",
  "80%",
  "90%",
];

export const answerData = ["Yes(at least 1 field)", "Yes(all Fields)", "No"];
