// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/homeBackground.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeContainer {
  width: 100dvw;
  margin: 0;
  padding: 0;
  background: transparent url(${___CSS_LOADER_URL_REPLACEMENT_0___}) bottom no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 40px;
}

.container-yes-no {
  display: flex;
  background-color: white;
  padding: 0px;
  height: auto;
}
.container-yes-no .icon-styles {
  height: auto;
}
.container-yes-no .yes-or-no {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACD,SAAA;EACA,UAAA;EACA,gFAAA;EACA,sBAAA;EACA,4BAAA;EACA,iBAAA;AACH;;AAGA;EACI,aAAA;EACA,uBAAA;EACA,YAAA;EACA,YAAA;AAAJ;AAEI;EACI,YAAA;AAAR;AAEI;EACI,OAAA;AAAR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
