// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancel-save {
  box-shadow: 0 1px 3px 0 rgba(54, 80, 94, 0.2);
}

.copy-delete {
  border: 2px solid transparent;
  width: 65px;
  height: 36px;
  box-shadow: 0 1px 3px 0 rgba(40, 60, 70, 0.2);
}
.copy-delete .copy {
  width: 30px;
  height: 30px;
}
.copy-delete .copy path {
  stroke: #EEEEEE;
}
.copy-delete .copy:hover path {
  stroke: #000000;
}
.copy-delete .delete {
  width: 30px;
  height: 30px;
}
.copy-delete .delete path {
  stroke: #EEEEEE;
}
.copy-delete .delete:hover path {
  stroke: #000000;
}`, "",{"version":3,"sources":["webpack://./src/components/yesorno/yesorno.scss"],"names":[],"mappings":"AAAA;EACI,6CAAA;AACJ;;AAEA;EACI,6BAAA;EACA,WAAA;EACA,YAAA;EACA,6CAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;AACR;AAAQ;EACI,eAAA;AAEZ;AAAQ;EACI,eAAA;AAEZ;AAEI;EACI,WAAA;EACA,YAAA;AAAR;AACQ;EACI,eAAA;AACZ;AACQ;EACI,eAAA;AACZ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
