import { Button, Group, Stack, ThemeIcon } from "@mantine/core";
import {
  IconChartPie,
  IconCircleArrowDownFilled,
  IconCircleCheck,
  IconCircleHalf2,
  IconDice6Filled,
  IconGitMerge,
  IconHeading,
  IconLetterA,
  IconMedal,
  IconMessage,
  IconThumbUp,
  IconTrendingDown,
} from "@tabler/icons-react";
import React, { useReducer } from "react";
import "./toolbar.scss";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { addQuestion } from "@components/redux/slice/questionsslice";
import { useSelector } from "react-redux";

const ToolBarData = [
  [
    { title: "Multiple Choice", type: "multipleChoice", Icon: IconCircleCheck },
    {
      title: "Multi Choice Grid",
      type: "multiChoiceGrid",
      Icon: IconCircleCheck,
    },
    {
      title: "Drop Down",
      type: "dropDown",
      Icon: IconCircleArrowDownFilled,
    },
    {
      title: "Drop Down Grid",
      type: "dropDownGrid",
      Icon: IconCircleArrowDownFilled,
    },
    { title: "Yes / No", type: "yesOrNo", Icon: IconCircleHalf2 },
    { title: "Net Promtor", type: "netPromoterScore", Icon: IconThumbUp },
  ],
  [
    { title: "Text Field", type: "textField", Icon: IconMessage },
    { title: "Text Field Grid", type: "textFieldGrid", Icon: IconMessage },
  ],
  [
    { title: "Rating Scale", type: "ratingScale", Icon: IconTrendingDown },
    {
      title: "Rating Matrix",
      type: "ratingScaleMatrix",
      Icon: IconDice6Filled,
    },
    { title: "Ranking", type: "ranking", Icon: IconMedal },
    { title: "Percentage/Sum", type: "percentageSum", Icon: IconChartPie },
  ],
  [
    { title: "Heading", type: "heading", Icon: IconHeading },
    { title: "Custom Text", type: "customText", Icon: IconLetterA },
    { title: "Score Display", type: "scoreDisplay", Icon: IconMedal },
    { title: "Page Break", type: "pageBreak", Icon: IconGitMerge },
  ],
];

const ToolBar = () => {
  const selectorQuestion = useSelector(
    (state: any) => state.questionList || []
  );
  const dispatch = useDispatch();
  const toolbarReducer = (state: any, action: any) => {
    switch (action.type) {
      default:
        return state;
    }
  };
  const genrerateDragbleData = () => {
    return ToolBarData.map((groupData) =>
      groupData.map((data) => ({ id: dayjs().unix(), data }))
    );
  };
  const [toolbarData, updateToolbarData] = useReducer(
    toolbarReducer,
    genrerateDragbleData()
  );

  const onDoubleClickHandler = (type: any) => {
    dispatch(addQuestion(type));
  };

  return (
    <Stack gap={10} className="toolbar">
      {ToolBarData.map((group) => (
        <Group>
          {group.map(({ title, Icon, type }) => (
            <Button
              leftSection={
                <ThemeIcon variant="white" color="rgba(67, 174, 216)">
                  <Icon />
                </ThemeIcon>
              }
              variant="outline"
              color="dark"
              onDoubleClick={() => onDoubleClickHandler(type)}
            >
              {title}
            </Button>
          ))}
        </Group>
      ))}
      <Button className="gallery">Templete Gallery</Button>
    </Stack>
  );
};

export default ToolBar;
