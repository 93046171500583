import {
  Box,
  Button,
  Checkbox,
  Flex,
  Group,
  Input,
  Modal,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import React, { useReducer, useRef, useState } from "react";
import "./dropdown.scss";
import {
  IconArrowsMove,
  IconCopy,
  IconExclamationCircleFilled,
  IconPencil,
  IconPlus,
  IconStack3,
  IconTrash,
} from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";
import ShowMore from "./showmore";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { deleteQuetion } from "@components/redux/slice/questionsslice";
import Colors from "../../utils/COLORS";

const DropDown: React.FC<any> = ({ data, id }) => {
  const [enable, setEnable] = useState(false);
  const [opened, { toggle, close }] = useDisclosure(false);

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "Choices":
        return {
          ...state,
          choices: state?.choices?.map((item: any) =>
            item.id === action.payload.id
              ? { ...item, option: action.payload.value }
              : item
          ),
        };

      case "Addchoices":
        return {
          ...state,
          choices: [
            ...state.choices,
            {
              id: dayjs().unix(),
              option: "option",
            },
          ],
        };

      case "deleteChoices":
        return {
          ...state,
          choices: state.choices.filter(
            (item: any) => item.id !== action.payload
          ),
        };

      case "hideNumber":
      case "isRequired":
      case "displayOrder":
        return {
          ...state,
          options: {
            ...state.options,
            [action.type]: !state.options?.[action.type],
          },
        };

      case "updateOptions":
        const result = action.payload;
        const resultArray = result.substring(1, result.length - 1).split("\\n");

        return {
          ...state,
          choices: resultArray
            .filter((item: any) => item.trim() !== "")
            .map((item: any) => ({
              id: dayjs().unix(),
              option: item,
            })),
        };

      case "identifier":
        return {
          ...state,
          options: { ...state.options, identifier: action.payload },
        };

      case action.payload:
        return {
          ...state,
          question: action.payload,
        };
    }
  };

  const [objectData, updateData] = useReducer(reducer, data);

  const dispatch = useDispatch();

  const inputRef = useRef<any>(null);

  const handleShowMore = () => {
    setEnable(true);
  };

  return (
    <Flex>
      <Box bg={Colors?.primary}>
        <IconPencil width={40} className="icon-pencil" />
      </Box>
      <Stack flex={3} p={10}>
        <Group justify="space-between" flex={2} className="header">
          <Text size="xl">Drop Down</Text>
          <Group gap={10}>
            <Group gap={0} className="cancel-save">
              <Button
                variant="subtle"
                radius={0}
                onClick={() => dispatch(deleteQuetion(id))}
              >
                Cancel
              </Button>
              <Button color={Colors?.green} radius={0}>
                Save
              </Button>
            </Group>
            <Group>
              <Tooltip withArrow label="copy">
                <IconCopy className="copy" />
              </Tooltip>
              <Tooltip withArrow label="delete">
                <IconTrash className="delete" />
              </Tooltip>
            </Group>
          </Group>
        </Group>
        <Text c={Colors?.textColor}>Question</Text>
        <Input
          value={objectData?.question}
          radius={0}
          onChange={(e) =>
            updateData({ type: "question", payload: e.target.value })
          }
        />
        <Text size="xl">Options</Text>
        <Group>
          <Text w={180} c={Colors?.textColor}>
            Required
          </Text>
          <Checkbox
            label="Respondents must answer this question."
            onChange={() => updateData({ type: "isRequired" })}
          />
        </Group>
        <Group>
          <Text w={180} c={Colors?.textColor}>
            Hide Number
          </Text>
          <Checkbox
            label="Hide the question number."
            onChange={(e) => updateData({ type: "hideNumber" })}
          />
        </Group>
        {!enable && (
          <a href="#" onClick={handleShowMore}>
            Show more Options
          </a>
        )}
        {enable && <ShowMore data={objectData} updateData={updateData} />}

        <Text size="xl">Choices</Text>
        <Stack gap={5}>
          <Text ml={40} c={Colors?.textColor}>
            Label
          </Text>
          {objectData?.choices?.map((item: any, index: number) => (
            <Group key={index}>
              <IconArrowsMove color={String(Colors.textColor)} />
              <Input
                value={item.option}
                flex={2}
                onChange={(e) => {
                  console.log(item),
                    updateData({
                      type: "Choices",
                      payload: { id: item.id, value: e.target.value },
                    });
                }}
              />
              <IconTrash
                color={String(Colors.textColor)}
                onClick={() =>
                  updateData({ type: "deleteChoices", payload: item.id })
                }
              />
            </Group>
          ))}

          <Group ml={40}>
            <Button
              leftSection={<IconPlus />}
              variant="outline"
              color="black"
              onClick={(e) => updateData({ type: "Addchoices" })}
            >
              Add Choice
            </Button>
            <Button
              leftSection={<IconStack3 />}
              variant="outline"
              color="black"
              onClick={toggle}
            >
              Bulk Add
            </Button>
            <Modal opened={opened} withCloseButton onClose={close} size="l">
              <Stack gap={5}>
                <Text size="xl">Quick entry</Text>
                <Text>Enter one choice per line.</Text>
                <Textarea autosize minRows={7} maxRows={10} ref={inputRef} />
                <Group>
                  <IconExclamationCircleFilled />
                  <Text>
                    Adding in bulk will replace your existing choices with those
                    entered.
                  </Text>
                </Group>
                <Group justify="flex-end" gap={0}>
                  <Button variant="subtle" radius={0} onClick={close}>
                    Close
                  </Button>
                  <Button
                    variant="filled"
                    radius={0}
                    onClick={() => {
                      updateData({
                        type: "updateOptions",
                        payload: JSON.stringify(inputRef.current?.value),
                      });
                      close();
                    }}
                  >
                    Save Changes
                  </Button>
                </Group>
              </Stack>
            </Modal>
          </Group>
        </Stack>
        <Group gap={0} justify="flex-end">
          <Button
            variant="subtle"
            color={Colors?.primary}
            radius={0}
            className="cancel-save"
            onClick={() => dispatch(deleteQuetion(id))}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color={Colors.green}
            radius={0}
            className="cancel-save"
          >
            Save
          </Button>
        </Group>
      </Stack>
    </Flex>
  );
};

export default DropDown;
