// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-editor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.menu {
  width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.note-title {
  width: 600px;
  margin-top: 10px;
}
.note-title input {
  width: 600px;
  padding: 2px;
}

.note-content {
  width: 600px;
  height: 100%;
}
.note-content textarea {
  width: 600px;
  height: 300px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/notes/editor.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AACJ;;AAEA;EACI,YAAA;EACA,gBAAA;AACJ;AACI;EACI,YAAA;EACA,YAAA;AACR;;AAGA;EACI,YAAA;EACA,YAAA;AAAJ;AAEI;EACI,YAAA;EACA,aAAA;EACA,gBAAA;AAAR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
