// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  background: rgba(0, 0, 0, 0.2);
  padding: 45px 30px 30px 30px;
  max-width: 1170px;
}
.container .midContainer {
  min-height: 400px;
  background-color: white;
  display: flex;
  justify-content: center;
  width: 800px;
}
.container .chooseText {
  text-align: center;
  width: auto;
  padding-bottom: 40px;
}
.container .chooseText .text {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/content/content.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,4BAAA;EACA,iBAAA;AACJ;AACI;EACI,iBAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;AACR;AAGI;EACI,kBAAA;EACA,WAAA;EACA,oBAAA;AADR;AAGQ;EACI,eAAA;AADZ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
