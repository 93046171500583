import YesOrNo from "../yesorno/YesOrNo";
import Ranking from "../ranking";
import DropDownGrid from "../dropDownGrid/DropDownGrid";
import DropDown from "../dropDown/dropDown";

const QuetionsComponent: any = {
  yesOrNo: YesOrNo,
  dropDownGrid: DropDownGrid,
  ranking: Ranking,
  dropDown: DropDown,
};

export default QuetionsComponent;
