// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy path {
  stroke: rgb(245, 240, 240);
}
.copy:hover path {
  stroke: black;
}

.delete path {
  stroke: rgb(245, 240, 240);
}
.delete:hover path {
  stroke: black;
}

.cancel-save {
  box-shadow: 0 1px 3px 0 rgba(54, 80, 94, 0.2);
}

.icon-pencil path {
  stroke: azure;
}`, "",{"version":3,"sources":["webpack://./src/components/dropDownGrid/dropdown.scss"],"names":[],"mappings":"AACI;EACI,0BAAA;AAAR;AAEI;EACI,aAAA;AAAR;;AAKI;EACI,0BAAA;AAFR;AAII;EACI,aAAA;AAFR;;AAMA;EACI,6CAAA;AAHJ;;AAMI;EACI,aAAA;AAHR","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
