import { combineReducers } from "@reduxjs/toolkit";
import Notes from "./notes/notes.slice";
import QuetionList from "./components/redux/slice/questionsslice";

const rootReducer = combineReducers({
  QuetionList,
  Notes,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
