import React, { useMemo } from "react";
import "./content.scss";
import { Container, Grid, GridCol, Group, Stack, Text } from "@mantine/core";
import {
  IconArrowBigRightFilled,
  IconArrowWaveRightDown,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "root.reducer";
import QuetionsComponent from "@components/quetionComponent/QuestionComponents";
import ToolBar from "@components/toolbar";

const Content = () => {
  const { questions } = useSelector((state: RootState) => state.QuetionList);

  const len = useMemo(() => questions.length, [questions]);
  return (
    <div className="mainContainer">
      <Container className="container">
        <Grid className="chooseText">
          <Grid.Col span={10} pt={10}>
            <Text className="text" draggable="true">
              Choose a template, or drag and drop a question to get started
            </Text>
          </Grid.Col>
          <Grid.Col span={2}>
            <IconArrowWaveRightDown />
          </Grid.Col>
        </Grid>

        <Group align="flex-start">
          <Stack className="midContainer" p={0}>
            {!len && (
              <Stack className="midContainerr" align="center">
                <Grid>
                  <GridCol span={11}>
                    <Text>Drag a question from the list on the right</Text>
                  </GridCol>
                  <GridCol span={1}>
                    <IconArrowBigRightFilled />
                  </GridCol>
                </Grid>
                <Text>
                  or, you can
                  <Link to="/p/charming"> learn more about FourEyes here</Link>
                  :-)
                </Text>
              </Stack>
            )}
            {!!len &&
              questions.map(({ id, data, type }) => {
                const Component = QuetionsComponent[type];
                return <Component data={data} id={id} />;
              })}
          </Stack>
          <ToolBar />
        </Group>
      </Container>
    </div>
  );
};

export default Content;
