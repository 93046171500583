import {
  Box,
  Button,
  Checkbox,
  Flex,
  Group,
  Input,
  Modal,
  Select,
  Stack,
  Text,
  Textarea,
  Tooltip,
} from "@mantine/core";
import React, { useReducer, useRef, useState } from "react";
import "./dropdown.scss";
import {
  IconArrowsMove,
  IconCopy,
  IconExclamationCircleFilled,
  IconPencil,
  IconPlus,
  IconStack3,
  IconTrash,
} from "@tabler/icons-react";

import { useDisclosure } from "@mantine/hooks";
import ShowMore from "./showmore";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { deleteQuetion } from "@components/redux/slice/questionsslice";
import Colors from "../../utils/COLORS";
import { answerData } from "./config";

const DropDownGrid: React.FC<any> = ({ data, id }) => {
  const [enable, setEnable] = useState(false);
  const [opened, { toggle, close }] = useDisclosure(false);

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "isRandomize":
      case "hideNumber":
      case "randomize":
        return {
          ...state,
          options: {
            ...state.options,
            [action.type]: !state.options[action.type],
          },
        };

      case "identifier":
      case "labelColWidth":
      case "answerRequired":
        return {
          ...state,
          options: {
            ...state.options,
            [action.type]: action.payload,
          },
        };

      case "Addchoices":
        return {
          ...state,
          choices: [
            ...state.choices,
            {
              id: dayjs().unix(),
              option: "option",
            },
          ],
        };

      case "DeleteChoices":
        return {
          ...state,
          choices: state?.choices?.filter(
            (item: any) => item.id !== action.payload
          ),
        };

      case "choices":
        return {
          ...state,
          choices: state.choices?.map((item: any) =>
            item.id === action.payload.id
              ? { ...item, option: action.payload.option }
              : item
          ),
        };

      case "addBulk":
        const val = action.payload;
        if (val === null || val === undefined) {
          return state;
        } else {
          return {
            ...state,
            choices: val
              ?.filter((item: string) => item.trim() !== "")
              .map((item: string, index: number) => ({
                id: dayjs().valueOf() + index,
                option: item,
              })),
          };
        }

      case "rowLabel":
        const result = action.payload;
        const resultVal = result.substring(1, result.length - 1).split("\\n");
        if (!resultVal) {
          return state;
        } else {
          return {
            ...state,
            fields: {
              ...state.fields,
              rowLabels: resultVal
                ?.filter((item: any) => item.trim() !== "")
                .map((item: any) => ({
                  id: dayjs().unix(),
                  row: item,
                })),
            },
          };
        }

      case "columnLabels":
        const columnValue = action.payload;
        const valueColumn = columnValue
          .substring(1, columnValue.length - 1)
          .split("\\n");
        if (!valueColumn) {
          return state;
        } else {
          return {
            ...state,
            fields: {
              ...state.fields,
              columnLabels: valueColumn
                ?.filter((item: any) => item.trim() !== "")
                .map((item: any) => ({
                  id: dayjs().unix(),
                  column: item,
                })),
            },
          };
        }

      case action.type:
        return { ...state, [action.type]: action.payload };

      default:
        return state;
    }
  };

  const [objectData, updateData] = useReducer(reducer, data);

  const inputRef = useRef<any>(null);

  const handleShowMore = () => {
    setEnable(true);
  };

  const dispatch = useDispatch();

  return (
    <Flex>
      <Box bg={Colors.primary}>
        <IconPencil width={40} className="icon-pencil" />
      </Box>
      <Stack flex={3} p={10}>
        <Group justify="space-between" flex={2} className="header">
          <Text size="xl">Drop Down Grid</Text>
          <Group gap={10}>
            <Group gap={0} className="cancel-save">
              <Button
                variant="subtle"
                radius={0}
                onClick={() => dispatch(deleteQuetion(id))}
              >
                Cancel
              </Button>
              <Button color={Colors.green} radius={0}>
                Save
              </Button>
            </Group>
            <Group>
              <Tooltip withArrow label="copy">
                <IconCopy className="copy" />
              </Tooltip>
              <Tooltip withArrow label="delete">
                <IconTrash className="delete" />
              </Tooltip>
            </Group>
          </Group>
        </Group>

        <Text c={Colors?.textColor}>Question</Text>
        <Input
          value={objectData?.question}
          onChange={(e) =>
            updateData({ type: "question", payload: e.target.value })
          }
          radius={0}
        />
        <Text size="xl">Options</Text>

        <Group>
          <Text w={180} c={Colors?.textColor}>
            Answer required
          </Text>
          <Select
            checkIconPosition="right"
            data={answerData}
            defaultValue="No"
            onChange={(value) =>
              updateData({ type: "answerRequired", payload: value })
            }
          />
        </Group>
        <Group>
          <Text w={180} c={Colors?.textColor}>
            Hide Number
          </Text>
          <Checkbox
            label="Hide the question number."
            onChange={(e) => updateData({ type: "hideNumber" })}
          />
        </Group>
        {!enable && (
          <a href="#" onClick={handleShowMore}>
            Show more Options
          </a>
        )}
        {enable && <ShowMore data={objectData} updateData={updateData} />}

        <Text size="xl">Fields</Text>
        <Group align="flex-start">
          <Text w={180} c={Colors?.textColor}>
            Row labels
          </Text>
          <Stack flex={1}>
            <Textarea
              resize="vertical"
              autosize
              minRows={7}
              maxRows={7}
              className="text-area"
              onChange={(e) =>
                updateData({
                  type: "rowLabel",
                  payload: JSON.stringify(e.target.value),
                })
              }
            />
            <Text>Each line represents a row, HTML is OK.</Text>
          </Stack>
        </Group>
        <Group align="flex-start">
          <Text w={180} c={Colors?.textColor}>
            Column labels
          </Text>
          <Stack flex={1}>
            <Textarea
              resize="vertical"
              autosize
              minRows={7}
              maxRows={7}
              className="text-area"
              onChange={(e) =>
                updateData({
                  type: "columnLabels",
                  payload: JSON.stringify(e.target.value),
                })
              }
            />
            <Text>Each line represents a column, HTML is OK.</Text>
          </Stack>
        </Group>

        <Text size="xl">Choices</Text>
        <Text ml={40} c={Colors?.textColor}>
          Label
        </Text>
        {objectData?.choices?.map(
          ({ id, option }: { id: number; option: string }) => {
            return (
              <Group>
                <IconArrowsMove color="#7d848c" />
                <Input
                  flex={2}
                  value={option}
                  onChange={(e) =>
                    updateData({
                      type: "choices",
                      payload: { id: id, option: e.target.value },
                    })
                  }
                />
                <IconTrash
                  color="#7d848c"
                  onClick={() =>
                    updateData({ type: "DeleteChoices", payload: id })
                  }
                />
              </Group>
            );
          }
        )}
        <Group ml={40}>
          <Button
            leftSection={<IconPlus />}
            variant="outline"
            color="black"
            onClick={(e) => updateData({ type: "Addchoices" })}
          >
            Add Choice
          </Button>
          <Button
            leftSection={<IconStack3 />}
            variant="outline"
            color="black"
            onClick={toggle}
          >
            Bulk Add
          </Button>
          <Modal opened={opened} withCloseButton onClose={close} size="l">
            <Text size="xl">Quick entry</Text>
            <Text>Enter one choice per line.</Text>
            <Textarea autosize minRows={7} maxRows={10} ref={inputRef} />
            <Group>
              <IconExclamationCircleFilled />
              <Text>
                Adding in bulk will replace your existing choices with those
                entered.
              </Text>
            </Group>
            <Group justify="flex-end" gap={0}>
              <Button variant="subtle" radius={0} onClick={close}>
                Close
              </Button>
              <Button
                variant="filled"
                radius={0}
                onClick={() => {
                  const data = JSON.stringify(inputRef.current?.value);
                  updateData({
                    type: "addBulk",
                    payload: data.substring(1, data.length - 1).split("\\n"),
                  });
                  close();
                }}
              >
                Save Changes
              </Button>
            </Group>
          </Modal>
        </Group>
        <Group gap={0} justify="flex-end">
          <Button
            variant="subtle"
            color={Colors?.primary}
            radius={0}
            className="cancel-save"
            onClick={() => dispatch(deleteQuetion(id))}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color={Colors?.green}
            radius={0}
            className="cancel-save"
          >
            Save
          </Button>
        </Group>
      </Stack>
    </Flex>
  );
};

export default DropDownGrid;
