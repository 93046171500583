import { Checkbox, FileInput, Group, Input, Stack, Text } from "@mantine/core";
import { IconHelpCircleFilled, IconPhoto } from "@tabler/icons-react";
import React from "react";
import Colors from "../../utils/COLORS";

const ShowMore: React.FC<any> = ({ updateData }) => {
  return (
    <Stack>
      <Group align="flex-start">
        <Text w={180} c={Colors?.textColor}>
          Display Order
        </Text>
        <Checkbox
          label="Randomize choices"
          onChange={() => updateData({ type: "displayOrder" })}
        />
      </Group>
      <Group>
        <Group w={180} gap={0}>
          <Text c={Colors?.textColor}>Identifier</Text>
          <IconHelpCircleFilled />
        </Group>
        <Input
          placeholder="Optional--Question text is used if Not Specified"
          flex={2}
          onChange={(e) =>
            updateData({ type: "identifier", payload: e.target.value })
          }
        />
      </Group>
      <Group>
        <Text w={180} c={Colors?.textColor}>
          Media
        </Text>
        <FileInput leftSection={<IconPhoto />} placeholder="UploadImage" />
      </Group>
    </Stack>
  );
};

export default ShowMore;
