import React, { useReducer, useState } from "react";
import "./yesorno.scss";
import {
  Button,
  Checkbox,
  Flex,
  Group,
  Input,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { IconCopy, IconPencil, IconTrash } from "@tabler/icons-react";
import More from "./more";
import Colors from "../../utils/COLORS";
import { useDispatch } from "react-redux";
import { deleteQuetion } from "@components/redux/slice/questionsslice";

const YesOrNo: React.FC<any> = ({ data, id }) => {
  const [enable, setEnable] = useState(false);

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "required":
      case "hideNumber":
        return {
          ...state,
          options: {
            ...state.options,
            [action.type]: !state.options[action.type],
          },
        };

      case "identifier":
      case "choiceLayout":
        return {
          ...state,
          options: {
            ...state.options,
            [action.type]: action.payload,
          },
        };
      case action.type:
        return { ...state, [action.type]: action.payload };
      default:
        return state;
    }
  };

  const [objectData, updateData] = useReducer(reducer, data);

  const dispatch = useDispatch();

  return (
    <Flex>
      <Stack bg={Colors?.primary} w={40} align="center">
        <IconPencil />
      </Stack>
      <Stack flex={2} p={10}>
        <Group justify="space-between">
          <Text size="lg">Yes / No</Text>
          <Group>
            <Group gap={0} className="cancel-save">
              <Button
                variant="subtle"
                color={Colors?.primary}
                radius={0}
                onClick={() => dispatch(deleteQuetion(id))}
              >
                Cancel
              </Button>
              <Button variant="filled" color={Colors.green} radius={0}>
                Save
              </Button>
            </Group>
            <Group className="copy-delete" gap={0}>
              <Tooltip
                withArrow
                transitionProps={{ duration: 200 }}
                label="copy"
              >
                <IconCopy className="copy" />
              </Tooltip>
              <Tooltip
                withArrow
                transitionProps={{ duration: 200 }}
                label="Delete"
              >
                <IconTrash className="delete" />
              </Tooltip>
            </Group>
          </Group>
        </Group>
        <Text size="md" c={Colors?.textColor}>
          Question
        </Text>
        <Input
          value={objectData?.question}
          onChange={(e) =>
            updateData({ type: "question", payload: e.target.value })
          }
        />
        <Text size="xl">Options</Text>
        <Group>
          <Text w={180} c={Colors?.textColor}>
            Requirement
          </Text>
          <Checkbox
            label="Respondent Must answer this quetion"
            onChange={() => updateData({ type: "required" })}
            value={objectData?.Requirement}
          />
        </Group>
        <Group>
          <Text w={180} c={Colors?.textColor}>
            Hide number
          </Text>
          <Checkbox
            label="Hide the question number"
            value={objectData?.hideNumber}
            onChange={() => updateData({ type: "hideNumber" })}
          />
        </Group>
        {!enable && (
          <a href="#" onClick={() => setEnable(true)}>
            Show all options
          </a>
        )}
        {enable && <More data={objectData} update={updateData} />}
        <Group gap={0} justify="flex-end">
          <Button
            variant="subtle"
            color={Colors?.primary}
            radius={0}
            className="cancel-save"
            onClick={() => dispatch(deleteQuetion(id))}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            color={Colors?.green}
            radius={0}
            className="cancel-save"
          >
            Save
          </Button>
        </Group>
      </Stack>
    </Flex>
  );
};

export default YesOrNo;
