import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import NoteEditor from "./notes/editor";
import Layout from "@components/layout";

import '@mantine/core/styles.css';
import "./app.scss";

import { createTheme, MantineProvider } from '@mantine/core';
import { Home } from "./pages";


const theme = createTheme({
  /** Put your mantine theme override here */
});

const App = () => {
  return (
    <Provider store={store}>
      <MantineProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Layout />} >
              <Route index element={<Home />} />
              <Route path="/new" element={<NoteEditor />} />
              <Route path="/note/:noteId" element={<NoteEditor />} />
            </Route>
          </Routes>
        </Router>
      </MantineProvider>
    </Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<App />);
