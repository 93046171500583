import Questions from "@components/utils";
import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

interface question {
  id: number;
  type: string;
  data: any;
}

interface QuestionState {
  editId: number;
  questions: question[];
}

const initialState: QuestionState = {
  editId: 0,
  questions: [],
};

export const questionList = createSlice({
  name: "questionList",
  initialState,
  reducers: {
    addQuestion: (state, action) => {
      const id = dayjs().unix();
      state.editId = id;
      state.questions.push({
        id,
        type: action.payload,
        data: Questions[action.payload],
      });
    },
    deleteQuetion: (state, action) => {
      console.log(action.payload);
      state.questions = state?.questions?.filter(
        (item: any) => item.id !== action.payload
      );
    },
  },
});

export const { addQuestion, deleteQuetion } = questionList.actions;

export default questionList.reducer;
