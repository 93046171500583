import { FileInput, Group, Input, Select, Stack, Text } from "@mantine/core";
import { IconPhoto, IconQuestionMark } from "@tabler/icons-react";
import React from "react";
import Colors from "../../utils/COLORS";
import { ConfigData } from "./config";

const More: React.FC<any> = ({ data, update }) => {
  return (
    <Stack>
      <Group>
        <Text w={180} c={Colors?.textColor}>
          Choice Layout
        </Text>
        <Select
          checkIconPosition="right"
          data={ConfigData?.layout}
          defaultValue="Horizontal"
          onChange={(value) => update({ type: "choiceLayout", payload: value })}
        />
      </Group>
      <Group>
        <Group w={180}>
          <Text c={Colors?.textColor}>Identifier </Text>
          <IconQuestionMark className="icon" />
        </Group>
        <Input
          placeholder="Optional---quetion text is used if not specified"
          flex={2}
          value={data?.options?.identifier}
          onChange={(e) =>
            update({ type: "identifier", payload: e.target.value })
          }
        />
      </Group>
      <Group className="media">
        <Text w={180} c={Colors?.textColor}>
          Media
        </Text>
        <FileInput leftSection={<IconPhoto />} placeholder="Attach an Image" />
      </Group>
    </Stack>
  );
};

export default More;
