// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copy-icon path {
  stroke: rgb(231, 227, 227);
}
.copy-icon:hover path {
  stroke: black;
}

.delete-icon path {
  stroke: rgb(231, 227, 227);
}
.delete-icon:hover path {
  stroke: rgba(0, 0, 0, 0.993);
}

.cancel-save {
  box-shadow: 0 1px 3px 0 rgba(54, 80, 94, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/components/ranking/ranking.scss"],"names":[],"mappings":"AACI;EACI,0BAAA;AAAR;AAEI;EACI,aAAA;AAAR;;AAKI;EACI,0BAAA;AAFR;AAII;EACI,4BAAA;AAFR;;AAMA;EACI,6CAAA;AAHJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
