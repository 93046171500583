import dayjs from "dayjs";

interface fields {
  id: number;
  Text: string;
}

const Questions: any = {
  multipleChoice: {
    question: "What question would you like to ask?",
    options: {
      isRequired: false,
      hideNumber: false,
      isRandomize: false,
      choiceLayout: "",
      media: "",
    },
    choices: ["option1", "option2", "option3"],
  },

  multiChoiceGrid: {
    question: "What question would you like to ask?",
    options: {
      isRequired: false,
      hideNumber: false,
      isRandomize: false,
      labelColWidth: "40%",
      identifier: "",
      media: "",
    },
    fields: ["Field 1", "Field 2", "Field 3"],
    choices: ["option1", "option2", "option3"],
  },

  dropDown: {
    question: "What question would you like to ask?",
    options: {
      isRequired: false,
      hideNumber: false,
      displayOrder: false,
      identifier: "",
      media: "",
    },
    choices: [
      { id: 1, option: "option1" },
      { id: 2, option: "option2" },
      { id: 3, option: "option3" },
    ],
  },

  dropDownGrid: {
    question: "What question would you like to ask?",
    options: {
      answerRequired: "No",
      hideNumber: false,
      isRandomize: false,
      randomize: false,
      labelColWidth: "40%",
      identifier: "",
      media: "",
    },
    fields: {
      rowLabels: [
        { id: 1, row: "row1" },
        { id: 2, row: "row2" },
      ],
      columnLabels: [
        { id: 1, column: "column1" },
        { id: 2, column: "column2" },
      ],
    },
    choices: [
      { id: 1, option: "option1" },
      { id: 2, option: "option2" },
      { id: 3, option: "option3" },
    ],
  },

  yesOrNo: {
    question: "What question would you like to ask?",
    options: {
      required: false,
      hideNumber: false,
      choiceLayout: "Horizontal",
      identifier: "",
      media: null,
    },
  },

  netPromoterScore: {
    question: "What question would you like to ask?",
    labels: true,
    leftLabel: "Not at all likely",
    rightLabel: "Extremly likely",
    options: {
      required: false,
      hideNumber: false,
      identifier: null,
      media: null,
    },
  },

  textField: {
    question: "What question would you like to ask?",
    answerTextArea: "single line",
    options: {
      required: false,
      hideNumber: false,
      identifier: "",
      media: null,
      initialValue: "",
      validation: "None",
    },
  },

  textFieldGrid: {
    question: "What question would you like to ask?",
    options: {
      answerRequired: "No",
      hideNumber: false,
      displayOrder: false,
      labelColumn: "40%",
      identifier: "",
      media: null,
    },
    fields: ["Field 1", "Field 2", "Field 3"],
    answerTextAreas: "single line",
  },

  ratingScale: {
    question: "What question would you like to ask?",
    options: {
      answerRequired: false,
      hideNumber: false,
      choiceLayout: "Vertical",
      identifier: null,
      media: null,
    },
    scale: {
      scaleType: "Agree - Disagree (5 levels)",
      displayMode: "Radio list",
      showNAOption: false,
      scoringDirection: "Ascending",
    },
  },

  ratingScaleMatrix: {
    question: "What question would you like to ask?",
    options: {
      isRequired: false,
      hideNumber: false,
      isRandomize: false,
      labelColWidth: "40%",
      identifier: "",
      media: "",
    },
    fields: [
      { id: 1, field: "field" },
      { id: 2, field: "field" },
      { id: 3, field: "field" },
    ],
    scale: {
      scaleType: "Agree - Disagree (5 levels)",
      displayMode: "Radio list",
      scaleLegend: "Yes, display as column labels",
      direction: "Ascending",
      hasNA: false,
    },
  },

  ranking: {
    question: "What question would you like to ask?",
    options: {
      isRequired: false,
      hideNumber: false,
      displayOrder: false,
      identifier: "",
      media: "",
    },
    fields: [
      { id: 1, field: "field1" },
      { id: 2, field: "field2" },
      { id: 3, field: "field3" },
    ],
  },

  percentageSum: {
    question: "What question would you like to ask?",
    options: {
      answerRequired: false,
      hideNumber: false,
      displayOrder: "Ordered as entered",
      identifier: "",
      media: "",
    },
    fields: ["field 1", "field 2", "field3"],
    sumOfFieldIs: "Equal To",
    value: "100",
    numericType: "Integer",
  },
};

export default Questions;
